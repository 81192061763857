<template>
  <div class="jion_us_all font14">
    <div v-if="!system.isPhone" class="left_kuai">
      <div>{{ $t("applyJoinUs.contactUs") }}</div>
      <div>
        <img
          class="left_icon"
          :src="require('@/assets/img/apply/email icon@2x.png')"
        />
        <a href="mailto:info@alpha-hk.com">
          <span>info@alpha-hk.com</span>
        </a>
      </div>
    </div>
    <div :class="system.isPhone ? 'wap_apply_form_all' : 'apply_form_all'">
      <el-form
        ref="applyJoinUsForm"
        :label-position="system.isPhone ? 'top' : 'right'"
        label-width="auto"
        :model="form"
        :rules="rules"
      >
        <div class="form_css">
          <!-- 代理方类型选项 -->
          <el-form-item>
            <div class="danxuan_all">
              <div
                v-for="(item, index) in $t('applyJoinUs.level')"
                :key="index"
                class="danxuan_list"
                :class="{ wap_en_danxuan_list: $t('languages') == 'EN' }"
                @click="qiehuanLevel(item.value)"
              >
                <img
                  v-if="item.value === form.level"
                  :src="require('@/assets/img/apply/quan.png')"
                />
                <img v-else :src="require('@/assets/img/apply/quan_wei.png')" />
                <span>{{ item.name }}</span>
              </div>
            </div>
          </el-form-item>
          <!-- 公司名称 -->
          <text-input
            prop="companyName"
            class="text_line"
            v-model="form.companyName"
            :l_icon="require('@/assets/img/apply/coolicon_1@2x.png')"
            :p="$t('applyJoinUs.companyName')"
          ></text-input>

          <!-- 邮箱 -->
          <text-input
            prop="email"
            class="text_line"
            v-model="form.email"
            :l_icon="require('@/assets/img/apply/email icon@2x.png')"
            :p="$t('applyJoinUs.email')"
          ></text-input>

          <!-- 联系人名字 -->
          <text-input
            prop="idcardName"
            class="text_line"
            v-model="form.idcardName"
            :l_icon="require('@/assets/img/apply/user icon@2x.png')"
            :p="$t('applyJoinUs.idcardName')"
          ></text-input>

          <!-- 联系人电话 -->
          <text-input
            prop="phone"
            class="text_line"
            v-model="form.phone"
            :l_icon="require('@/assets/img/apply/email icon@2x.png')"
            :p="$t('applyJoinUs.phone')"
          ></text-input>

          <!-- 公司地址 -->
          <text-input
            prop="companyAddress"
            class="text_line"
            v-model="form.companyAddress"
            :maxlength="100"
            :l_icon="require('@/assets/img/apply/coolicon_1@2x.png')"
            :p="$t('applyJoinUs.companyAddress')"
          ></text-input>
          <!-- 描述 -->
          <text-input
            prop="memo"
            inputType="textarea"
            class="text_line"
            v-model="form.memo"
            :maxlength="200"
            :l_icon="require('@/assets/img/apply/coolicon_1@2x.png')"
            :p="$t('applyJoinUs.memo')"
          ></text-input>

          <uplode-input
            @uplodeChange="uplodeChange"
            keyName="file"
          ></uplode-input>
        </div>

        <el-form-item class="but_clsss">
          <el-button
            class="next_but "
            type="primary"
            @click="submitForm('applyJoinUsForm')"
            >{{ $t("applyAgent.errMsg.nextStep") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import textInput from "@/views/formComponents/textInput";
import uplodeInput from "@/views/formComponents/uplodeInput2";
import { verifyEmail } from "@/utils/pattern.js";

export default {
  components: {
    textInput,
    uplodeInput,
  },
  data() {
    // var phone = (rule, value, callback) => {
    //   if (value == "") {
    //     callback(new Error(this.$t("applyAgent.errMsg.notNull")));
    //   } else {
    //     callback();
    //   }
    // };
    var email = (rule, value, callback) => {
      if (!verifyEmail(value)) {
        callback(new Error(this.$t("applyAgent.errMsg.emailnotallow")));
      } else {
        callback();
      }
    };
    var notNull = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t("applyAgent.errMsg.notNull")));
      } else {
        callback();
      }
    };
    // var img = (rule, value, callback) => {
    //   if (this.form[rule.field].indexOf("http") < 0) {
    //     callback(new Error(this.$t("applyAgent.errMsg.notNull")));
    //   } else {
    //     callback();
    //   }
    // };

    return {
      whoApply: "applyOversea",
      labelPosition: "right",
      form: {
        idcardName: "",
        phone: "",
        email: "",
        companyName: "",
        companyAddress: "",
        kind: "Supply",
        businessLine: 2,
        level: 1, //供应链等级（1：品牌方；2：总代；3：经销商；4：工厂）
        memo: "",
        data: {
          file: [],
        },
      },
      canSendCode: "false",
      rules: {
        phone: [{ required: true, validator: notNull }],
        email: [{ required: true, validator: email, trigger: "blur" }],
        companyName: [{ required: true, validator: notNull, trigger: "blur" }],
        idcardName: [{ required: true, validator: notNull, trigger: "blur" }],
        companyAddress: [{ required: true, validator: notNull }],
      },
    };
  },
  methods: {
    qiehuanLevel(value) {
      this.form.level = value;
      console.log(value);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tijiao();
        } else {
          // this.$message({
          //   message: "有必填项未填写",
          //   type: "warning"
          // });
          return false;
        }
      });
    },
    uplodeChange(value) {
      console.log(value);
      this.form.data.file[0] = value.file;
      // this.$refs["applyJoinUsForm"].validateField(Object.keys(value)[0]);
    },
    tijiao() {
      let data = { ...this.form };
      //进行提交
      this.$request("/api/front/userInfoCheck/addUserInfoCheck", {
        method: "POST",
        data,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$router.replace({
              path: "applyAfter",
              query: { status: "tijiaochenggong" },
            });
            // this.$message({
            //   message: "提交成功，等待审核",
            //   type: "success"
            // });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.but_clsss {
  max-width: 475px;
}
.zanwei {
  height: 60px;
}
.left_icon {
  width: 20px;
  height: 16px;
  margin-right: 22px;
}
.next_but {
  width: 272px;
  height: 53px;
  border-radius: 67px;
  margin-top: 60px;
}
.jion_us_all {
  padding-top: 46px;
  display: flex;
  > div {
    flex: 3;
  }
}
.left_kuai {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    padding-left: calc(100vw * 9 / 192);
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.danxuan_all {
  display: flex;
  justify-content: flex-start;
  .danxuan_list {
    line-height: 20px;
    color: rgba(153, 153, 153, 1);
    margin-right: 30px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > img {
      margin-right: 4px;
      width: 16px;
      height: auto;
    }
  }
}
.apply_form_all {
  flex: 4 !important;
  width: 100%;
  .text_line {
    // padding-top: 18px;
    // padding-bottom: 18px;
  }
  /deep/.form_css {
    max-width: 475px;
    margin-bottom: 60px;
    margin-top: 36px;
    .el-form-item--mini {
      margin-bottom: 36px;
    }
    .el-input__prefix {
      align-items: center;
      display: flex;
      padding-left: 18px;
    }
    .el-input__inner {
      padding-left: 60px;
    }
    .el-textarea__inner.el-textarea__inner {
      height: 100px;
    }
  }
}
.wap_apply_form_all {
  .zanwei {
    height: calc(100vw * 1.2 / 37.5);
  }
  /deep/.el-form-item__content {
    text-align: center;
  }
  .next_but {
    width: calc(100vw * 32.5 / 37.5);
    height: calc(100vw * 4.2 / 37.5);
    opacity: 1;
    background: #1977fb;
    border-radius: 6px;
    margin: auto;
  }
  /deep/.form_css {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
    .danxuan_list {
      margin-right: calc(100vw * 2.5 / 37.5);
    }
    .wap_en_danxuan_list {
      margin-right: calc(100vw * 0.5 / 37.5);
    }
    .el-input__prefix {
      align-items: center;
      display: flex;
    }
    .el-textarea__inner {
      height: calc(100vw * 12 / 37.5);
      border: none;
      border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
    }
    .el-form-item__label {
      font-size: calc(100vw * 1.2 / 37.5);
      padding: 0;
      margin-top: calc(100vw * 2.4 / 37.5);
    }
    .el-input__inner {
      font-size: calc(100vw * 1.2 / 37.5);
      height: auto;
      line-height: calc(100vw * 3.2 / 37.5);
      border-radius: 0px;
      border: 1px none #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
    }
    .red {
      line-height: 20px;
    }
  }
}
</style>
